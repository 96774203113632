import React from "react"

class Footer extends React.Component {
  render () {
    return (
      <footer className="grey-text no-print"></footer>
    );
  }
}

export default Footer;
